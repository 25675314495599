/* You can add global styles to this file, and also import other style files */
@import "/projects/tours-lib/src/lib/settings";
@import "~foundation-sites/scss/foundation";
@import '~@angular/cdk/overlay-prebuilt.css';


@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-visibility-classes;
@include foundation-title-bar;
@include foundation-menu-icon;
@include foundation-float-classes;
@include foundation-callout;

.cdk-overlay-container .cdk-overlay-dark-backdrop {
	background-color: rgba(0, 0, 0, 0.7);
}

.full-width {
	width: 100%;
}

/*.responsive-backdrop {
	background-color: rgba(0, 0, 0, 0.6);

	@include breakpoint(medium) {
		background-color: rgba(0, 0, 0, 0);
	}
}*/

.home-page {
	.hero-image {
		background-image: url("https://tours.com.ua/images/hero.jpg");
		background-size: cover;
	}
}

html, body {
	height: 100%;
}

body {
	margin: 0;
	font-family: "Roboto", sans-serif;
}
